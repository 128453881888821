<template>
<div>   
<XModal :name="name" :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="true" >
<div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">


             <div style='display: inline-flex; float: left; font-weight: bold; fonz-size: 9pt; padding-top: 2pt; margin-right: 15pt;'>
             Issues for {{bill.invoiceNumber}}
             </div>
            
             <div :style="'float:right; padding-right: 2pt; background-color: transparent !important;'" >
                  <WindowCloseBN @click="close"/>
		      </div>
            
         
        <div class="modal-body" id='main-body' :style="getAppStyle()+'height: 100%;'">
	  
	      <div  >
          <slot name="body" :style='getAppStyle()'>
             
			<div v-for="(i,i1) in issues" :key="'i1'+i1">
			  <div v-if="Object.keys(i).length">
			    <div v-if="Object.keys(i).length==1">
			  	 {{i}}
			  	</div>
			  	<div v-else>
				  	<div v-for="(j, i2) in i" :key="'i1'+i1+'_'+i2">
					  {{j}} ({{i2}})
					</div>
			  	</div>
			  </div>
            </div>
	      </slot>
	      </div>
	     
	      </div>
		</div>	
	  </XModal>

</div>   
</template>
<script>
import { formatNumber} from '@/variables.js';
import { tr } from '@/translate.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import { setGoBack } from '@/breadCrumb.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
export default {
  props: {
    name: {
      type: String,
      default: 'ShowBillIssues'
    },
  	 height: {
      type: Number,
      default: 75
    }
  },
 components : {
	 WindowCloseBN
  },
  data () {
    return {
      width: 66,
      bill: {},
      issues: {},
      tr, formatNumber, getAppStyle
    }
  },
  created() 
  {
  		initAppMode();
  },
 methods: {
getTableStyle() {
        
     	//return "overflow-y: scroll; height: calc(60vH - 104px)";
     	return "overflow-y: auto; height: calc("+this.height+"vH - 104px)";
     },	
 close() {  this.$modal.hide(this.name); this.$emit("close") },
 open( bill, issues) 
 {
    this.bill = bill;
    this.issues = issues;
 	this.$modal.show(this.name);
 }
 }
  
}
</script>
<style lang="scss" scoped>

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  
  }
.headerDay { 
  font-size: 12pt; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
 }
.adMain { 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  z-Index: 17; 
  background-color: #fff; 
  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 

    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}

.gridMain { position: absolute; z-Index: 0; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 8pt;
}
.issueText {
	font-size: 9pt;
}
.btn {
	height: 2.2em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
	font-size: 8pt;
}
.thead {
	font-size: 9pt;
	color: #fff;
	background-color: #444;
	font-weight: bold;
}
</style>